<template>
  <div class="login-container">
    <el-image class="logo_login" :src="require('../assets/logo.png')" fit></el-image>
    <el-card class="login_card">
      <div slot="header" class="clearfix">
        <span>登录后台管理系统</span>
      </div>
      <el-form :model="loginForm" :rules="loginRules" status-icon ref="LoginForm" v-show="!isSignUp">
        <el-form-item prop="username">
          <el-input type="text" v-model="loginForm.username" auto-complete="off" placeholder="用户名" prefix-icon="el-icon-user" @keyup.enter.native="handleSubmit"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="loginForm.password" auto-complete="off" placeholder="密码" prefix-icon="el-icon-key" @keyup.enter.native="handleSubmit"></el-input>
        </el-form-item>
        <el-form-item style="width:100%;">
          <el-button type="primary" style="width:100%;" @click="handleSubmit" :loading="logining" :disabled="!canSubmit">登录</el-button>
          <br>
          <!-- <el-button style="width:100%;margin-top:10px;" @click="handleSignUp">注册</el-button> -->
        </el-form-item>
      </el-form>
      <!-- <el-form :model="signUpForm" :rules="signUpRule" status-icon ref="SignUpForm" v-show="isSignUp">
        <el-form-item prop="username">
          <el-input type="text" v-model="signUpForm.username" auto-complete="off" placeholder="用户名" prefix-icon="el-icon-user"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" v-model="signUpForm.password" auto-complete="off" placeholder="密码" prefix-icon="el-icon-key"></el-input>
        </el-form-item>
        <el-form-item prop="email">
          <el-input type="text" v-model="signUpForm.email" auto-complete="off" placeholder="邮箱" prefix-icon="el-icon-message"></el-input>
        </el-form-item>
        <el-form-item style="width:100%;">
          <el-button type="primary" style="width:100%;" :disabled="!canSignUp" @click="clickSignUp">注册</el-button>
          <br>
          <el-button style="width:100%;margin-top:10px;" @click="handleSignUp" :loading="logining">返回登录</el-button>
        </el-form-item>
      </el-form> -->
    </el-card>
  </div>
</template>

<script>
  import {
    createNamespacedHelpers
  } from "vuex"
  const admin = createNamespacedHelpers('admin')
  export default {
    data() {
      // var checkEmail = (rule, value, callback) => {
      //   if(!value) {
      //     return callback(new Error('邮箱不能为空'))
      //   } else if (!this.isAvailableEmail(value)) {
      //     return callback(new Error('邮箱格式不正确'))
      //   }
      // }
      var checkUsername = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('用户名不能为空'))
        }
      }
      var checkPassword = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('密码不能为空'))
        }
      }
      return {
        logining: false,
        isSignUp: false,
        loginForm: {
          username: '',
          password: '',
        },
        // signUpForm: {
        //   email: '',
        //   username: '',
        //   password: '',
        // },
        loginRules: {
          username: [{
            validator: checkUsername,
            trigger: 'blur'
          }],
          password: [{
            validator: checkPassword,
            trigger: 'blur'
          }],
        },
        // signUpRule: {
        //   email: [{
        //     validator: checkEmail,
        //     trigger: 'blur'
        //   }],
        //   username: [{
        //     validator: checkUsername,
        //     trigger: 'blur'
        //   }],
        //   password: [{
        //     validator: checkPassword,
        //     trigger: 'blur'
        //   }],
        // }
      }
    },
    computed: {
      canSubmit() {
        return this.loginForm.username && this.loginForm.password
      },
      // canSignUp() {
      //   return this.signUpForm.email && this.signUpForm.username && this.signUpForm.password
      // }
    },
    methods: {
      ...admin.mapActions(['login']),
      handleSubmit() {
        if (this.canSubmit) {
          this.login(this.loginForm).then(() => {
            this.$message({
              type: 'success',
              message: '登录成功'
            })
            this.$router.push('/')
          }).catch((error) => {
            this.$message.error('登录失败')
          })
        } else {
          this.$message.error('请正确输入用户名以及密码')
        }
      },
      // handleSignUp() {
      //   this.isSignUp = !this.isSignUp
      //   let that = this
      //   this.$refs['SignUpForm'].resetFields()
      //   this.$refs['LoginForm'].resetFields()
      // },
      // isAvailableEmail(email) {
      //   var reg = /^([\w+.])+@\w+([.]\w+)+$/
      //   return reg.test(email)
      // },
      // clickSignUp() {
      //   console.log(this.signUpForm)
      // },
    }
  }
</script>

<style scoped>
  .login_card {
    width: 298px;
  }

  .login-container {
    position: absolute;
    top: 30%;
    left: calc(50% - 150px)
  }

  .login-page {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    width: 300px;
    padding: 35px 35px 15px;
    background: #fff;
    border: 1px solid #eaeaea;
    box-shadow: 0 0 25px #cac6c6;
  }

  .logo_login {
    position: absolute;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    top: -140px;
    left: 75px;
    box-shadow: 0px 0px 10px #409EFF;
  }
  
</style>